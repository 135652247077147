<template>
	<div align = "center">
		<v-carousel
			:height="size > 600 ? 'calc(100vh - 64px)' : 'calc(100vh - 56px)'"
			hide-delimiter-background
			cycle
		>
			<v-carousel-item v-for="(item, i) in carrusel" :key="i" :src="item"></v-carousel-item>
		</v-carousel>

		<v-sheet color="second" dark class="py-6 img" :class="size > 600 ?'px-12':'px-3'">
			<v-row no-gutters>
				<v-col cols="12" class="d-block mb-3">
					<h3 class="text-h4 text-center mt-4">
						BIENVENIDOS A
						<span class="text-uppercase">{{ marca }}</span>
					</h3>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="12" lg="5" v-if="$store.state.id_tw">
					<div class="py-12">
						<a
							class="twitter-timeline"
							data-height="500"
							data-dnt="true"
							data-theme="light"
							data-chrome="noheader"
							:href="$store.state.id_tw"
						>X by track_races</a>

						
					</div>
				</v-col>
				<v-col v-if="$store.state.id_tw"></v-col>
				<v-col cols="12" md="12" lg="5">
					<form class="py-12" @submit.prevent="send">
						<div class="text-center">
							<span class="text-button">contactanos</span>
							<p class="text-caption">
								Estamos para ayudarle a resolver cualquier duda que tengas.
								<br />Por favor llene los siguentes campos.
							</p>
						</div>
						<v-row>
							<v-col>
								<v-text-field
									solo
									required
									light
									dense
									background-color="#eceff1"
									flat
									hide-details
									type="email"
									class="mr-auto"
									append-icon="mdi-email"
									label="Tu correo"
									v-model="correo"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-text-field
									solo
									required
									light
									dense
									background-color="#eceff1"
									flat
									hide-details
									type="text"
									class="mr-auto"
									append-icon="mdi-text"
									label="Asunto del mensaje"
									v-model="asunto"
								></v-text-field>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-textarea
									solo
									v-model="msj"
									required
									light
									dense
									background-color="#eceff1"
									flat
									hide-details
									type="email"
									class="mr-auto"
									label="Escribe el mensaje"
								></v-textarea>
							</v-col>
						</v-row>
						<v-btn :loading="loading" block depressed class="mt-3" color="#fff" light type="submit">Enviar</v-btn>
						<p class="text-center mt-6 mb-0 text-overline">Le responderemos tan pronto como sea prosible.</p>
						<!-- <h6 class="text-center subtitle-1">Estamos para ayudarle a resolver cualquier duda que tengas.</h6> -->
						<!-- <h6 class="text-center caption">Contactanos al siguiente correo electronico</h6> -->
						<div class="d-flex flex-column align-center">
							
							<div >
								<v-icon size="30">mdi-email-outline</v-icon>
								<h7 class="ml-2 body-1 font-weight-medium">{{ email }}</h7>
							</div>
								<div>
								<v-btn @click="open($store.state.social.youtube)">
								<v-icon size="32">mdi-whatsapp</v-icon>
								<h5 class="ml-2 body-1 font-weight-medium">{{ $store.state.social.telefono}}</h5>
								</v-btn>
								</div>
							
							
						</div>
					</form>
				</v-col>
			</v-row>
		</v-sheet>
		<v-dialog v-model="dialog" max-width="350">
			<v-card>
				<v-card-title class="headline">¡Mensaje enviado!</v-card-title>

				<v-card-text>
					Le reponderemos con la mayor brevedad prosible a su correo
					electronico
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="green darken-1" text @click="dialog = false">Ok</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="no" max-width="350">
			<v-card>
				<v-card-title class="headline">¡no se pudo enviar!</v-card-title>

				<v-card-text>No pudimos enviar el mensaje, por favor vuela a intentar</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="green darken-1" text @click="dialog = false">Ok</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- <v-dialog v-model="dialogRules">
			<v-card>
				<v-icon
					style="position:absolute;z-index:99;right:6px;top:6px"
					size="40"
					class
					dark
					@click="dialogRules = false"
				>mdi-close</v-icon>
				<v-img src="../assets/img/rules.jpg"></v-img>
			</v-card>
		</v-dialog>-->
	</div>
</template>

<script>
import axios from "axios";

export default {
	props: {
		source: String,
	},

	data: () => ({
		loading: false,
		dialog: false,
		no: false,
		correo: "",
		asunto: "",
		msj: "",
	}),
	mounted() {
		this.tw();
	},
	computed: {
		colors() {
			return this.$store.state.colors;
		},
		marca() {
			return this.$store.state.marca;
		},
		email() {
			return this.$store.state.email;
		},
		telefono() {
			return this.$store.state.telefono;
		},
		size() {
			return this.$store.state.windowSize.x;
		},
		token() {
			return this.$store.state.token;
		},
		carrusel() {
			let c = [];
			this.$store.state.carrusel.map((item) => {
				if (item != null) {
					c.push(item);
				}
			});
			return c;
		},
	},

	created() {
		this.$store.state.token = sessionStorage.getItem("token");
	},
	methods: {
		tw() {
			let script = document.createElement("script");
			script.src = "https://platform.twitter.com/widgets.js";
			script.async = false; //IMPORTANT
			document.body.appendChild(script);
		},
		open(link) {
			window.open(link, "_blank");
		},
		send() {
			this.loading = true;
			axios
				.post(
					"https://us-central1-trackandraces-845ca.cloudfunctions.net/traces",
					{
						to: "soporte@trackandraces.com",
						subject: `Home trackandraces: ${this.asunto}`,
						message: `
						Asunto: Home trackandraces: ${this.asunto}
						<br/>
						Correo usuario: ${this.correo}
						<br/>
						mensaje: ${this.msj}
						`,
					}
				)
				.then((response) => {
					//console.log(response);

					if (response.data.message == "email sent") {
						this.dialog = true;
						this.correo = "";
						this.asunto = "";
						this.msj = "";
					} else {
						this.no = true;
					}
					this.loading = false;
				});
		},
	},
};
</script>

<style>
.t-title {
	font-weight: 600;
	font-size: 0.975rem !important;
	letter-spacing: 0.5px;
}
.img {
	background-image: url("../assets/img/fondo derecha.svg");
	background-repeat: repeat;
	background-blend-mode: color-dodge;
	background-position: center;
}
</style>